<template>
  <div class="about">
    <div class="hero is-info">
      <div class="container">
        <h1 class="is-size-3">{{ $t("register.title") }}</h1>
      </div>
    </div>
    <hr>
    <div class="columns is-centered">
      <div class="column is-5-tablet is-4-desktop is-3-widescreen">
        <div class="box">
          <div class="field">
            <label for="" class="label">{{ $t("register.email") }}</label>
            <div class="control has-icons-left">
              <input type="email" v-bind:placeholder="$t('register.ph-email')" class="input" v-model="user.email" required>
            </div>
          </div>
          <div class="field">
            <label for="" class="label">{{ $t("register.password") }}</label>
            <div class="control has-icons-left">
              <input type="password" class="input" v-model="user.password" required>
            </div>
          </div>
          <div class="field">
            <label for="" class="label">{{ $t("register.confirmPassword") }}</label>
            <div class="control has-icons-left">
              <input type="password" class="input" v-model="confirmPassword" required>
            </div>
          </div>
          <div class="field">
            <label for="" class="label">{{ $t("register.display") }}</label>
            <div class="control has-icons-left">
              <input type="text" v-bind:placeholder="$t('profile.ph-display')" class="input" v-model="user.displayName">
            </div>
          </div>

          <div class="field">
            <label for="" class="label">{{ $t("register.name") }}</label>
            <div class="control has-icons-left">
              <input type="text" v-bind:placeholder="$t('register.ph-name')" class="input" v-model="user.name">
            </div>
          </div>

          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <button class="button is-info" @click="save" v-tooltip="$t('register.save')">
                  <font-awesome-icon icon="save" />
                </button>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <button class="button is-dark" @click="reset" type="reset" v-tooltip="$t('register.reset')">
                  <font-awesome-icon icon="eraser" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {UserClient} from "@/pb/user_service_grpc_web_pb";
import common from "@/utility/common";
import {AddUserRequest} from "@/pb/user_service_pb";
import {DetailAddress, Origin, UserProfile} from "@/pb/model_pb";
import environment from "@/utility/environment";

export default {
  name: 'Register',
  data() {
    return {
      user: {},
      confirmPassword: ""
    }
  },
  methods: {
    reset: function() {
      this.user = {}
      this.confirmPassword = ""
    },
    save: function() {
      if (this.user.password != this.confirmPassword) {
        alert("password not matching, fix password then retry")
        return
      }
      let today = new Date()
      let ts = new proto.google.protobuf.Timestamp()
      ts.setSeconds(Math.floor(today.getTime() / 1000))
      ts.setNanos((today.getTime() % 1000) * 1e6)
      let token = this.$cookies.get("$t")
      let metadata = {'authorization': token}
      this.client = new UserClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      let addUserRequest = new AddUserRequest()
      let userToAdd = new UserProfile()
      userToAdd.setEmail(this.user.email)
      userToAdd.setPassword(this.user.password)
      userToAdd.setName(this.user.name)
      userToAdd.setOrganization(this.user.organization)
      userToAdd.setRole(this.user.role)
      userToAdd.setCreatedAt(ts)
      userToAdd.setStatus(this.user.status)
      userToAdd.setDisplayName(this.user.displayName)
      userToAdd.setCurrentToken(this.user.currentToken)
      userToAdd.setPhoneNumber(this.user.phoneNumber)
      let address = this.user.address
      if (address == null || address == undefined) {
        address = new DetailAddress()
        address.setStreetNumber("")
        address.setCity('')
        address.setProvince("")
        address.setCountry("USA")
        address.setZipCode("")
        address.setLatitude(0.0)
        address.setLongtitude(0.0)
        address.setTimezone("")
      }
      userToAdd.setAddress(address)
      userToAdd.setUpdatedAt(ts)
      let origin = new Origin()
      origin.setLoginAt(ts)
      origin.setBrowser(environment.getBrowser())
      origin.setOs(environment.getOS())
      let origins = []
      origins = this.user.origins
      if (origins == [] || origins == undefined || origins == null) {
        origins = []
      }
      origins.push(origin)
      userToAdd.setOriginsList(origins)
      addUserRequest.setUser(userToAdd)
      this.client.addUser(addUserRequest, metadata, (err, response)=>{
        if (err != null) {
          alert("Failed to create user: " + err)
          return
        }
        if (response != null) {
          alert(response.getMessage())
        }
      })
    }
  }
}
</script>